<template>
    <div class="flex flex-col w-full max-w-2xl mt-8 mx-auto p-2">
        <form-field-wrapper id="field_name" :error="errorBag.get('name')">
            <div class="relative">
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                    <div v-if="openAiLoading" class="h-8 w-8">
                        <app-icon class="h-8 w-8 text-gray-500" name="loader"></app-icon>
                    </div>
                </div>

                <input
                    id="input_name"
                    v-model="name"
                    autofocus
                    class="form-field pr-16 transition"
                    placeholder="Type a descriptive event name..."
                >
            </div>

            <p
                v-if="openAiLoading"
                id="text_processing"
                class="animate-pulse text-xs text-gray-500 text-center mt-2"
            >Our AI Concierge is customizing your experience. Please give us a moment...</p>
        </form-field-wrapper>

        <stateful-button
            id="button_continue"
            type="button"
            class="button button-primary button-lg h-12 w-full mt-8 disabled:opacity-50 disabled:cursor-not-allowed"
            :loading="completing"
            :disabled="!canContinue"
            @click="complete"
        >
            Continue To Next Step
        </stateful-button>

        <div v-if="canSkipToSignup && !openAiLoading" class="text-center">
            <button
                id="button_skip"
                class="text-sm text-purple font-medium hover:text-purple-light mt-10"
                type="button"
                @click="$emit('skip-to-signup')"
            >
                Skip creating an event and sign up
            </button>

            <p id="text_just_rsvp" class="text-center">
                <a
                    id="link_just_rsvp"
                    class="text-sm text-purple font-medium hover:text-purple-light"
                    href="https://help.rsvpify.com/en/articles/1222578-i-am-a-guest-trying-to-rsvp-and-can-t-find-or-access-an-event-that-i-need-to-rsvp-to-help"
                    target="_blank"
                >
                    I just want to RSVP to someone else's event
                </a>
            </p>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { sync } from 'vuex-pathify';
import axiosLib from 'axios';
import axios from '@/util/axios';
import Errors from '@/validation/Errors';
import DetectsCordova from '@/check-in/Mixins/DetectsCordova';

export default {
    name: 'EventNameStep',

    mixins: [DetectsCordova],

    props: {
        canSkipToSignup: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            completing: false,
            errorBag: new Errors(),
            openAiLoading: false,
            pendingRequestCancelToken: null
        };
    },

    computed: {
        name: sync('CreateEvent/name'),
        primaryEventTypeId: sync('CreateEvent/primary_event_type_id'),
        secondaryEventTypeIds: sync('CreateEvent/secondary_event_type_ids'),
        canContinue: sync('CreateEvent/canContinue')
    },

    watch: {
        name (newVal) {
            this.cancelPendingRequest();

            this.errorBag.clear();
            this.canContinue = false;

            if (newVal.length > 0) {
                this.issueCancelToken();
                this.initiateEventTypeDetection();
            }
        }
    },

    methods: {
        cancelPendingRequest () {
            if (this.pendingRequestCancelToken) {
                this.pendingRequestCancelToken.cancel();
                this.pendingRequestCancelToken = null;
            }
        },

        complete () {
            this.completing = true;
            this.$emit('completed');
        },

        initiateEventTypeDetection: debounce(async function () {
            try {
                this.openAiLoading = true;
                this.canContinue = false;

                this.loadEventTypes(await this.detectEventTypes());

                this.canContinue = true;
            } catch (error) {
                if (axiosLib.isCancel(error)) {
                    return;
                }

                if (error.response?.status !== 422) {
                    throw error;
                }

                this.errorBag.record(error.response.data.errors);
            } finally {
                this.openAiLoading = false;
            }
        }, 500),

        async detectEventTypes () {
            const { data } = await axios.post(
                this.route('api.event-type-detection.store'),
                { name: this.name },
                { cancelToken: this.pendingRequestCancelToken.token }
            );

            return data.data;
        },

        issueCancelToken () {
            this.pendingRequestCancelToken = axiosLib.CancelToken.source();
        },

        loadEventTypes (eventTypes) {
            if (eventTypes.length === 0) {
                this.primaryEventTypeId = null;
                this.secondaryEventTypeIds = [];
            } else {
                this.primaryEventTypeId = eventTypes[0].id;
                this.secondaryEventTypeIds = eventTypes.slice(1).map(({ id }) => {
                    return id;
                });

                if (!window.user?.id && eventTypes[0].cms.name === 'Baby Shower' && !this.isCordova) {
                    App.alert().confirm(
                        'Get Early Access to Our Newest Version',
                        '',
                        {
                            confirmButtonText: 'Continue to New Version',
                            cancelButtonText: 'Sign Up for Current Version',
                            icon: null,
                            customClass: {
                                confirmButton: 'button button-primary mx-2',
                                cancelButton: 'button mx-2',
                                popup: 'swal2-wide'
                            },
                            html: 'We’re launching a new version of RSVPify<br>designed for events just like yours! 🎉<br><br>As part of our limited rollout, get exclusive<br>early access—completely free.',
                            reverseButtons: true
                        },
                        () => {
                            const eventName = encodeURIComponent(this.name);
                            window.location.href = `https://app.rsvpify.com/onboarding?workspace=personal&name=${eventName}`;
                        }
                    );
                }
            }
        }
    }
};
</script>
